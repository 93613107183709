import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { route } from 'nextjs-routes';

import useApiQuery from 'lib/api/useApiQuery';
import useIsMobile from 'lib/hooks/useIsMobile';
import useNewTxsSocket from 'lib/hooks/useNewTxsSocket';
import { TX } from 'stubs/tx';
import LinkInternal from 'ui/shared/LinkInternal';
import SocketNewItemsNotice from 'ui/shared/SocketNewItemsNotice';

import LatestTxsItem from './LatestTxsItem';
import LatestTxsItemMobile from './LatestTxsItemMobile';

const BLOCKED_ADDRESS = "0x251Da4C62b32ae1403d815c05d72A94E633d0214";

const LatestTransactions = () => {
  const isMobile = useIsMobile();
  const txsCount = isMobile ? 2 : 6;
  const { data, isPlaceholderData, isError } = useApiQuery('homepage_txs', {
    queryOptions: {
      placeholderData: Array(txsCount).fill(TX),
    },
  });

  const { num, socketAlert } = useNewTxsSocket();

  if (isError) {
    return <Text mt={4}>No data. Please reload page.</Text>;
  }

  if (data) {
    const filteredData = data.filter((tx) => {
      const from = tx.from?.hash || ""; // Pega o endereço de 'from' ou string vazia se não existir
      const to = tx.to?.hash || "";     // Pega o endereço de 'to' ou string vazia se não existir
    
      return from !== BLOCKED_ADDRESS && to !== BLOCKED_ADDRESS;
    });

    const txsUrl = route({ pathname: '/txs' });
    return (
      <>
        <SocketNewItemsNotice borderBottomRadius={0} url={txsUrl} num={num} alert={socketAlert} isLoading={isPlaceholderData} />
        <Box mb={3} display={{ base: 'block', lg: 'none' }}>
          {filteredData.slice(0, txsCount).map((tx, index) => (
            <LatestTxsItemMobile key={tx.hash + (isPlaceholderData ? index : '')} tx={tx} isLoading={isPlaceholderData} />
          ))}
        </Box>
        <Box mb={4} display={{ base: 'none', lg: 'block' }}>
          {filteredData.slice(0, txsCount).map((tx, index) => (
            <LatestTxsItem key={tx.hash + (isPlaceholderData ? index : '')} tx={tx} isLoading={isPlaceholderData} />
          ))}
        </Box>
        <Flex justifyContent="center">
          <LinkInternal fontSize="sm" href={txsUrl}>View all transactions</LinkInternal>
        </Flex>
      </>
    );
  }

  return null;
};

export default LatestTransactions;
